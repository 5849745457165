import BaseValidation from '@/forms/Validations/BaseValidation';
import { isUSAIP, shouldShowOptIn } from '@/store';

export const names = {
  NewsletterOptin: 'NewsletterOptin',
  DisneyOptin: 'DisneyOptin',
}

export default (path: string = '') => {
  const newsletterOptin = {
    name: names.NewsletterOptin,
    rules: { 
      required: (val) => {
        if (shouldShowOptIn()) return val
        return true
      }
    },
    messages: {
      required: 'Please check the optin',
    },
    modelValue: false,
  }
  const disneyOptin = { name: names.DisneyOptin, rules: {}, messages: {}, modelValue: false }
  const validationsArr: any[] = [newsletterOptin]
  if (isUSAIP()) validationsArr.push(disneyOptin)
  return (
    new BaseValidation(
      validationsArr,
      path,
    )
)}