import { Module, ActionTree, MutationTree, GetterTree, Store } from 'vuex';

import { RootState } from '@/store/types';

export enum Country {
  US = 1,
  AUS = 2,
  WORLD = 5,
  UK = 6,
}

export enum SubportalType {
  AffinityCharter = 1,
  TravelAgent = 2,
  MyLex = 3,
  FriendsAndFamily = 4,
  OnlineDocs = 5,
}

export enum BusinessContextType {
  Default = 0,
  MyLex = 1,
  TravelAgent = 2,
  FriendsAndFamily = 4,
  OnlineDocs = 5,
  NG = 6,
  AffinityCharter = 7,
  Hyatt = 8,
  Undefined = 100,
}

export const SubportalTypeValues: { [key: string]: SubportalType } = {
  [SubportalType[SubportalType.AffinityCharter]]: SubportalType.AffinityCharter,
  [SubportalType[SubportalType.TravelAgent]]: SubportalType.TravelAgent,
  [SubportalType[SubportalType.MyLex]]: SubportalType.MyLex,
  [SubportalType[SubportalType.FriendsAndFamily]]: SubportalType.FriendsAndFamily,
  [SubportalType[SubportalType.OnlineDocs]]: SubportalType.OnlineDocs,
};

export interface PageMeta {
  Country: Country;
  CF_Country?: string;
  SubPortal: SubportalType;
  Destination?: { Id: number };
  BusinessContext: BusinessContextType;
  BookingUrl?: string;
  Culture: string;
  ContextMeta: {
    Destinations?: string,
    Destination?: string,
    Itinerary?: any,
  },
  Analytics: {
    page_type_id: string,
    page_type_name: string,
    categories?: Array<AnaliticsCategory>,
    products?: Array<AnaliticsProduct>,
    brochures?: Array<AnaliticsBrochures>
  }
}

interface AnaliticsCategory {
  category: string;
}

interface AnaliticsProduct {
  brand: string,
  image_url: string,
  name: string,
  product_id: string,
}

interface AnaliticsBrochures {
  brochure_id: string,
  brochure_name: string,
  brochure_code: string
}

const actions: ActionTree<PageMeta, RootState> = {};

export const CHANGE_SUBPORTAL = 'CHANGE_SUBPORTAL';
const mutations: MutationTree<PageMeta> = {
  [CHANGE_SUBPORTAL](state, newSubportal: SubportalType) {
    if (state) {
      state.SubPortal = newSubportal;
    }
  },
};

export const PAGE_META = 'PAGE_META';
export const IS_HYATT = 'IS_HYATT';
export interface PageMetaGetters {
  [PAGE_META]: PageMeta;
  [IS_HYATT]: boolean;
}

const getters: GetterTree<PageMeta, RootState> = {
  [PAGE_META](state) {
    return state;
  },
  [IS_HYATT](state) {
    return state.BusinessContext === BusinessContextType.Hyatt;
  },
};

export default (meta: PageMeta) =>
  ({
    namespaced: false,
    state: meta,
    actions,
    mutations,
    getters,
  } as Module<PageMeta, RootState>);
