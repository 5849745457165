import { Component, Mixins, Prop } from 'vue-property-decorator';

import EmailRow from '@/forms/FormElements/Rows/EmailRow/EmailRow.vue';
import NameRow from '@/forms/FormElements/Rows/NameRow/NameRow.vue';
import DebugModel from '@/forms/FormElements/Common/DebugModel/DebugModel.vue';
import ButtonRow from '@/forms/FormElements/Rows/ButtonRow/ButtonRow.vue';
import CheckBoxRow from '@/forms/FormElements/Rows/CheckBoxRow/CheckBoxRow.vue';
import TermsAndConditionsRow from '@/forms/FormElements/Rows/TermsAndConditionsRow/TermsAndConditionsRow.vue';

import { EmailSignUpUser, names } from './EmailSignUpForm.types';
import Message from '@/forms/Validations/Message';
import getValidation from '@/modules/EmailSignUpModule/EmailSignUpForm/EmailSignUpForm.validations';
import { CtaButtonStyle } from '@/common/interfaces/CtaLinkItem';

import FormValidationMixin from '@/forms/FormElements/Mixins/FormValidationMixin';
import NewsletterOptinMixin from '@/forms/FormElements/Mixins/NewsletterOptinMixin';
import { DISNEY_OPTIN_HTML } from '@/utils/contentConst';
import { isUSAIP } from '@/store';

const { validations, model, messages } = getValidation();

@Component({
  validations,
  components: {
    DebugModel,
    NameRow,
    EmailRow,
    TermsAndConditionsRow,
    ButtonRow,
    CheckBoxRow,
  },
})
export default class EmailSignUpForm extends Mixins(FormValidationMixin, NewsletterOptinMixin) {
  names = names;

  model: EmailSignUpUser = model;

  messages: Message[] = messages;

  buttonStylePrimaryDarkBlack = CtaButtonStyle.PrimaryDarkBlack;

  disneyOptinContent = DISNEY_OPTIN_HTML;

  @Prop()
  readonly BottomText!: string;

  @Prop()
  readonly CtaButtonText!: string;

  get isUSAGeo() {
    return isUSAIP()
  }
}
